import * as React from "react";
import { ThemeContext } from "context-api/ThemeContext";
import styles from "./styles.module.css";

interface IData {
  max_fare: string;
  min_fare: string;
  avg_fare: string;
  count: string;
  period: string;
  carrier: {
    color: string;
    value: string;
  };
}
interface Props {
  data: IData[];
}
const StatisticsComponent: React.FC<Props> = ({ data }) => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <div className={styles.container}>
      <div className={styles.fields} data-theme={theme}>
        <span className={styles.field} data-theme={theme}>
          Carrier
        </span>
        <span className={styles.field} data-theme={theme}>
          Max Fare
        </span>
        <span className={styles.field} data-theme={theme}>
          MIN Fare
        </span>
        <span className={styles.field} data-theme={theme}>
          Count
        </span>
        <span className={styles.field} data-theme={theme}>
          AVG Fare
        </span>
      </div>
      {data?.map((el, index) => (
        <div key={index} className={styles.row}>
          <span className={styles.field} data-theme={theme}>
            <span
              className={styles.carrier}
              style={{
                backgroundColor: el?.carrier?.color ?? "#fff",
              }}
            >
              {el?.carrier?.value ?? "-"}
            </span>
          </span>
          <span className={styles.field} data-theme={theme}>
            {el?.max_fare ?? "-"}
          </span>
          <span className={styles.field} data-theme={theme}>
            {el?.min_fare ?? "-"}
          </span>
          <span className={styles.field} data-theme={theme}>
            {el?.count ?? "-"}
          </span>
          <span className={styles.field} data-theme={theme}>
            {el?.avg_fare ?? "-"}
          </span>
        </div>
      ))}
    </div>
  );
};
export default StatisticsComponent;
