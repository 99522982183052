import moment from "moment";

export const weekDays : string[] = [
    "Monday", 
    "Tuesday", 
    "Wednesday", 
    "Thursday", 
    "Friday", 
    "Saturday",
    "Sunday"
];

// use this function to get days of week 
// if for some reason days of week chaned then i can change logic here to get days of week as a string of list always 
export const getDaysOfWeekAsString = () : string[] => weekDays

export const formatDateFromNumber = (dateOnly:number,del:string='-') : string => {
    let str=String(dateOnly);
    return `${str.substr(0,4)}${del}${str.substr(4,2)}${del}${str.substr(6,2)}`
}

export const formatDateFromNumberCustomFormat = (dateOnly:number,format:string = 'YYYY-MM-DD') : string => {
    // let str=String(dateOnly);
    // let year=Number(str.substr(0,4));
    // let month=Number(str.substr(4,2));
    // let day=Number(str.substr(6,4));
    const m=moment(String(dateOnly))
    return `${m.format(format)}`;
}

// pass date parts (year,month,day) -> YYYY-MM-DD as number 
export const formatDateFromParts = (year:number,month : number,day : number,del:string='-') : string => {
    const _month:string = month.toString().length === 1 ?
        "0" + month.toString() :
        month.toString() ; 

        const _day:string = day.toString().length === 1 ?
        "0" + day.toString() :
        day.toString() ; 
    return `${year.toString()}${del}${_month}${del}${_day}` ;
}

// TODO add test
export const formatTimeFromNumber = (timeOnly:number) => {
    const _val = `${timeOnly}` 
    if (isNaN(timeOnly)) return _val
    if (_val.length === 1) return `0${_val}:00`
    if (_val.length === 2) return `00:${_val}`
    if (_val.length === 3) return `0${_val.slice(0,1)}:${_val.slice(1,)}`
    return `${_val.slice(0,2)}:${_val.slice(2,)}`
}


// get days for each month as strings ([Sat,Sun,Mon ...... ])
export const getDaysArray = (year : number, month : number) : string[] => {
        const days : string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const date : Date = new Date(year, month-1, 1);
        //const date : Date = new Date(year, month-1, 1);

        const result : string[] = [];
        while (date.getMonth() === month -1) {
            result.push(days[date.getDay()]);
            date.setDate(date.getDate() + 1);
        }
        return result;
};


export const getMonthAsSatring = (monthAsNumber : number) :  string => {
    const months : string[] = ["jan", "feb", "mar", "apr", "may", "jun", "jul", 
    "aug", "sep", "oct", "nov", "dec"];
    if (monthAsNumber > 12 || monthAsNumber < 1) return '' ; 
    return months[monthAsNumber - 1].toUpperCase()
}

