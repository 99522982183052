/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./BookingTrends.module.css";
import settingIcon from "../../../assets/icons/element-button-minimize-copy-2@3x2.webp";
import { BookingTrendsClient } from "lib/api/msd/bookingTrends/bookingTrends";
import { FilterContext } from "context-api/FilterContext";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import { BookingTrendsHolidaysClient } from "lib/api/msd/bookingTrendsHolidaysCountry";
import images from "constans/images";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import Plotly from "components/common/Charts/Plotly";
import Dropdown from "components/common/Dropdown/Dropdown";
import ColumnControl from "utils/ColumnControl";
// import CardInfo from "components/common/CardInfo/CardInfo";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { Tooltip } from "components/common/Tooltip";

interface IBookingTrends {
  filterIsOpen: boolean;
}
interface IRangeSlider {
  value: number[];
  min: number;
  max: number;
  dateList: string[];
}
export default function BookingTrends({ filterIsOpen }: IBookingTrends) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [rangeValue, setRangeValue] = useState<IRangeSlider>({
    value: [],
    min: 0,
    max: 20,
    dateList: [],
  });
  const [trendsAndDetail, setTrendsAndDetail] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [viewIsActiveHistorical, setViewIsActiveHistorical] = useState(true);
  const [viewIsActiveForward, setViewIsActiveForward] = useState(true);
  const [monthlyDaily, setMonthlyDaily] = useState(false);
  const [holidaysCountry, setHolidaysCountry] = useState<string[]>([]);
  const [selectedHolidaysCountry, setSelectedHolidaysCountry] = useState<
    string[]
  >([]);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [isHolidaysSelected, setIsHolidaysSelected] = useState(false);
  const { filterList } = useContext(FilterContext);
  const [chartData, setChartData] = useState<any>([]);
  const [tableData, setTableData] = useState({ data: [], columns: [] });
  const [layout, setLayout] = useState([]);
  const [selectedHolidays, setSelectedHolidays] = useState<string[]>([]);
  const wrapperRef: any = useRef(null);
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });

  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      agg_type: monthlyDaily ? "daily" : "monthly",
      time_direction: getViewButtonValue(),
    })
      .then((response: any) => {
        const param = ["max", "dateList", "value"];
        setRangeValue((prevState) => ({
          ...prevState,
          [param[0]]: response.values.length - 1,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[1]]: response.values,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[2]]: [response.start_idx, response.end_idx],
        }));
      })
      .catch(() => {
        console.log("Error");
      });
  }, [monthlyDaily, viewIsActiveForward, viewIsActiveHistorical]);

  useEffect(() => {
    if (!filterIsOpen) {
      BookingTrendsHolidaysClient.fetchBookingTrendsHolidays({
        filterList,
      })
        .then((response: any) => {
          setHolidaysCountry(response.options);
          setSelectedHolidaysCountry(response.options ?? []);
          setCarriersJson(response?.carriers);
          setIsCountrySelected(true);
        })
        .catch(() => {
          console.log("Error");
        });
    }
  }, [filterIsOpen, filterList]);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.dateList.length > 0 && isCountrySelected) {
      BookingTrendsClient.fetchBookingTrendsClient({
        filterList,
        graphCarriers: selectedCarriers.join(),
        time_direction: getViewButtonValue(),
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        agg_type: monthlyDaily ? "daily" : "monthly",
        holiday_countries: selectedHolidaysCountry.join(),
        show_holidays: "1",
        selected_holidays: "All",
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setTableData({
            data: response.holidays_table.data.map((el) => ({
              ...el,
              id: el?.holiday_idx,
            })),
            columns: getColumns(response.holidays_table.labels),
          });
        })
        .catch(() => {
          setTableData({ data: [], columns: [] });
        });
    }
  }, [
    filterIsOpen,
    filterList,
    rangeValue.value,
    selectedCarriers,
    selectedHolidaysCountry,
    isCountrySelected,
  ]);

  useEffect(() => {
    if (
      !filterIsOpen &&
      rangeValue.dateList.length > 0 &&
      isCountrySelected &&
      isHolidaysSelected
    ) {
      BookingTrendsClient.fetchBookingTrendsClient({
        filterList,
        graphCarriers: selectedCarriers.join(),
        time_direction: getViewButtonValue(),
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        agg_type: monthlyDaily ? "daily" : "monthly",
        holiday_countries: selectedHolidaysCountry.join(),
        show_holidays: "1",
        selected_holidays:
          selectedHolidays.length > 0 ? selectedHolidays.join() : "All",
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setChartData(response.fig.data);
          setLayout(response.fig.layout);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setChartData([]);
        });
    }
  }, [selectedHolidays]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors]);

  const getViewButtonValue = () => {
    if (viewIsActiveForward && viewIsActiveHistorical) {
      return "historical-forward";
    } else if (viewIsActiveForward && !viewIsActiveHistorical) {
      return "forward";
    } else if (!viewIsActiveForward && viewIsActiveHistorical) {
      return "historical";
    } else {
      setViewIsActiveForward(true);
      return "forward";
    }
  };

  const getColumns = (labels) => {
    const array: any = [];
    labels.forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        const obj = {
          name,
          field,
          valueGetter: (row) =>
            ColumnControl({ value: row[field], isView: false }),
          cell: (row) => (
            <Tooltip title={ColumnControl({ value: row[field], isView: true })}>
              <span className={"rdb-custom-cell"}>
                {ColumnControl({ value: row[field], isView: true })}
              </span>
            </Tooltip>
          ),
        };
        array.push(obj);
      }
    });
    return array;
  };

  const handleSelectedHolidays = useCallback((state) => {
    const array = state.map((obj) => obj?.holiday_idx);
    if (array.join("") !== selectedHolidays.join("")) {
      setSelectedHolidays(array);
      setIsHolidaysSelected(true);
    }
  }, []);

  return (
    <Card variant="secondary" zIndex={6}>
      <div className={styles.card_header}>
        <span className={styles.card_title} data-theme={theme}>
          Booking Trends
          {/* <CardInfo
            title={storyText?.main_card?.title ?? null}
            description={storyText?.main_card?.content ?? null}
          /> */}
        </span>
        <div className={styles.trends_and_detail_button_wrapper}>
          <span className={styles.holidays_text} data-theme={theme}>
            Holidays:
          </span>
          <div className={styles.trends_and_detail_button} data-theme={theme}>
            <span
              className={styles.button_text}
              data-active={!trendsAndDetail}
              onClick={() => setTrendsAndDetail(!trendsAndDetail)}
              data-theme={theme}
            >
              Trends
            </span>
            <span
              className={styles.button_text}
              data-active={trendsAndDetail}
              onClick={() => setTrendsAndDetail(!trendsAndDetail)}
              data-theme={theme}
            >
              Detail
            </span>
          </div>
        </div>
      </div>
      <div className={styles.trends_wrapper} data-active={trendsAndDetail}>
        <div className={styles.content}>
          <div className={styles.carriers_wrapper}>
            <Carriers
              data={GetCarriers()}
              added={selectedCarriers}
              setAdded={setSelectedCarriers}
              carriersJson={carriersJson}
            />
          </div>
          <div className={styles.open_menu_wrapper} ref={wrapperRef}>
            <img
              src={theme === "dark" ? settingIcon : images.setting_button_light}
              alt=""
              className={styles.setting_icon}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            <div
              className={styles.open_menu}
              data-active={isOpen}
              data-theme={theme}
            >
              <div className={styles.open_menu_card} data-theme={theme}>
                <div className={styles.open_menu_title_wrapper}>
                  <span className={styles.title_text} data-theme={theme}>
                    View
                  </span>
                  <img
                    src={images.eye}
                    alt=""
                    className={styles.open_menu_icons}
                    data-theme={theme}
                  />
                </div>
                <div className={styles.open_menu_buttons_wrapper}>
                  <span
                    className={styles.open_menu_buttons}
                    data-active={viewIsActiveForward}
                    onClick={() => setViewIsActiveForward(!viewIsActiveForward)}
                    data-theme={theme}
                  >
                    Forward
                  </span>
                  <span
                    className={styles.open_menu_buttons}
                    data-active={viewIsActiveHistorical}
                    onClick={() =>
                      setViewIsActiveHistorical(!viewIsActiveHistorical)
                    }
                    data-theme={theme}
                  >
                    Historical
                  </span>
                </div>
              </div>
              <div className={styles.open_menu_card} data-theme={theme}>
                <div className={styles.open_menu_title_wrapper}>
                  <span className={styles.title_text} data-theme={theme}>
                    Time Range
                  </span>
                  <img
                    src={images.calendar}
                    alt=""
                    className={styles.open_menu_icons}
                    data-theme={theme}
                  />
                </div>
                <div className={styles.open_menu_buttons_wrapper}>
                  <span
                    className={styles.open_menu_buttons}
                    data-active={!monthlyDaily}
                    onClick={() => setMonthlyDaily(!monthlyDaily)}
                    data-theme={theme}
                  >
                    Monthly
                  </span>
                  <span
                    className={styles.open_menu_buttons}
                    data-active={monthlyDaily}
                    onClick={() => setMonthlyDaily(!monthlyDaily)}
                    data-theme={theme}
                  >
                    Daily
                  </span>
                </div>
              </div>
              <div className={styles.open_menu_card} data-theme={theme}>
                <div className={styles.open_menu_title_wrapper}>
                  <span className={styles.title_text} data-theme={theme}>
                    Holidays
                  </span>
                  <img
                    src={images.smileys}
                    alt=""
                    className={styles.open_menu_icons}
                    data-theme={theme}
                  />
                </div>
                <div>
                  <Dropdown
                    width={176}
                    data={holidaysCountry}
                    added={selectedHolidaysCountry}
                    setAdded={setSelectedHolidaysCountry}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card variant="primary">
          <div className={styles.chart_wrapper}>
            <p className={styles.chart_title} data-theme={theme}>
              # Passengers
            </p>
            <Plotly
              data={chartData}
              height={254}
              layout={layout}
              margin={{ t: 0, b: 20, l: 40, r: 0, p: 4 }}
            />
          </div>
          <div className={styles.range_wrapper}>
            <RangeSlider
              data={{
                max: rangeValue.max,
                min: rangeValue.min,
                value: rangeValue.value,
                dateList: rangeValue.dateList,
                setValue: setRangeValue,
              }}
              format={monthlyDaily ? "DD MMM YY" : "MMM YY"}
            />
          </div>
        </Card>
      </div>
      <div className={styles.detail_wrapper} data-active={!trendsAndDetail}>
        <CustomAtarevTable
          data={tableData.data}
          columns={tableData.columns}
          selectableRows={handleSelectedHolidays}
          pagination
          defaultSelected={tableData.data.length > 0}
        />
        <div className={styles.range_wrapper}>
          <RangeSlider
            data={{
              max: rangeValue.max,
              min: rangeValue.min,
              value: rangeValue.value,
              dateList: rangeValue.dateList,
              setValue: setRangeValue,
            }}
            format={monthlyDaily ? "DD MMM YY" : "MMM YY"}
          />
        </div>
      </div>
    </Card>
  );
}
