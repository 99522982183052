import { useContext, useState } from "react";
import { FilterContext } from "../../../context-api/FilterContext";
import RecommendationsTable from "components/lfa/RecommendationsTable";
import Layout from "components/common/Layout/Layout";

export default function BRERESULTS() {
  const { filterList } = useContext(FilterContext);
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Recommendations"}
    >
      <RecommendationsTable
        filters={{
          origAirport: filterList.origCityAirport,
          destAirport: filterList.destCityAirport,
          competitors: filterList.selectedCompetitors,
        }}
      />
    </Layout>
  );
}
