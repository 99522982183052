import { IFilterContext } from "types/types";
import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface ContextState {
  filterList: IFilterContext;
  setFilterList: any;
}

const contextDefaultValues: ContextState = {
  filterList: {
    origRegion: [],
    destRegion: [],
    origCountry: [],
    destCountry: [],
    origCityAirport: [],
    destCityAirport: [],
    pos: [],
    mainCompetitor: [],
    selectedCompetitors: [],
    salesChannel: [],
    cabin: [],
    paxType: [],
    currency: [],
  },
  setFilterList: () => {},
};
const FilterContext = React.createContext<ContextState>(contextDefaultValues);
const FilterConsumer = FilterContext.Consumer;

const FilterContextApp = ({ children }: Props) => {
  const [filterList, setFilterList] = useState<any>({
    origRegion: [],
    destRegion: [],
    origCountry: [],
    destCountry: [],
    origCityAirport: [],
    destCityAirport: [],
    pos: [],
    mainCompetitor: [],
    selectedCompetitors: [],
    salesChannel: [],
    cabin: [],
    paxType: [],
    currency: [],
  });

  return (
    <FilterContext.Provider value={{ filterList, setFilterList }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterConsumer, FilterContextApp };
