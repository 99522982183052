/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import SelectMenu from "components/common/SelectMenu";
import ListOfMarkets from "./ListOfMarkets";
import Input from "components/common/Input/input";
import { useEffect, useState } from "react";
import { IOption } from "types/global";
import { CustomersClient } from "lib/api/admin-panel/admin/customers";
import { ScraperMarketOptionsClient } from "lib/api/admin-panel/admin/scraper-configuration/scraper-market-options";
import { CustomerMarketsClient } from "lib/api/admin-panel/admin/scraper-configuration/customer-markets";
import Notification from "components/common/Notification/Notification";
import { CustomerMarketDetailClient } from "lib/api/admin-panel/admin/scraper-configuration/customer-markets-detail";

export default function ScrapersConfiguration() {
  const [customers, setCustomers] = useState<IOption[]>([]);
  const [customersSelected, setCustomerSelected] =
    useState<IOption | null>(null);
  const [countries, setCountries] = useState<IOption[]>([]);
  const [countriesSelectedOrigin, setCountriesSelectedOrigin] =
    useState<IOption | null>(null);
  const [countriesSelectedDestination, setCountriesSelectedDestination] =
    useState<IOption | null>(null);
  const [citiesOrigin, setCitiesOrigin] = useState<IOption[]>([]);
  const [citiesDestination, setCitiesDestination] = useState<IOption[]>([]);
  const [citiesSelectedOrigin, setCitiesSelectedOrigin] =
    useState<IOption | null>(null);
  const [citiesSelectedDestination, setCitiesSelectedDestination] =
    useState<IOption | null>(null);
  const [marketTypes, setMarketTypes] = useState<IOption[]>([]);
  const [marketTypeSelected, setMarketTypeSelected] =
    useState<IOption | null>(null);
  const [stayDuration, setStayDuration] = useState<string>("");
  const [currencies, setCurrencies] = useState<IOption[]>([]);
  const [currenciesSelected, setCurrenciesSelected] =
    useState<IOption | null>(null);
  const [startOffset, setStartOffset] = useState<string>("");
  const [endtOffset, setEndOffset] = useState<string>("");
  const [websites, setWebsites] = useState<IOption[]>([]);
  const [websiteSelected, setWebsiteSelected] = useState<IOption | null>(null);
  const [scrapedCurrency] = useState<IOption[]>([]);
  const [scrapedCurrencySelected, setScrapedCurrencySelected] =
    useState<IOption | null>(null);
  const [carrierToScrape] = useState<IOption[]>([]);
  const [carrierToScrapeSelected, setCarrierToScrapeSelected] =
    useState<IOption | null>(null);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    getCustomersOptions();
  }, []);

  const getCustomersOptions = () => {
    CustomersClient.fetchCustomers()
      .then((response: any) => {
        const array: IOption[] = response?.customers?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setCustomers(array);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customersSelected) {
      getFilterMarketOptions();
    }
  }, [
    customersSelected,
    countriesSelectedOrigin,
    countriesSelectedDestination,
  ]);

  const getFilterMarketOptions = () => {
    ScraperMarketOptionsClient.fetchFilterMarketOptions({
      customer: customersSelected?.value ?? "",
      orig_country: countriesSelectedOrigin?.value,
      dest_country: countriesSelectedDestination?.value,
    })
      .then((res: any) => {
        const res_countries: IOption[] = res?.countries?.map((el) => ({
          label: el?.country_code,
          value: el?.country_code,
          disabled: false,
        }));
        const res_orig_cities: IOption[] = res?.orig_airports?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        const res_dest_cities: IOption[] = res?.dest_airports?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        const res_market_types: IOption[] = res?.market_types?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        const res_currencies: IOption[] = res?.currencies?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        const res_websites: IOption[] = res?.websites?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setCountries(res_countries);
        setCitiesOrigin(res_orig_cities);
        setCitiesDestination(res_dest_cities);
        setMarketTypes(res_market_types);
        setCurrencies(res_currencies);
        setWebsites(res_websites);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (customersSelected) {
      getTableData();
    }
  }, [customersSelected]);

  const getTableData = () => {
    if (customersSelected) {
      CustomerMarketsClient.fetchCustomerMarkets({
        customer: customersSelected?.value,
      })
        .then((res: any) => {
          const tableArray = res?.table?.data?.map((el, index) => ({
            ...el,
            id: index,
          }));
          setData(tableArray);
          setLabels(res?.table?.labels);
        })
        .catch((err) =>
          Notification({
            type: "error",
            message: `${
              err?.originalError?.response?.data?.message ?? "Error"
            }`,
          })
        );
    }
  };

  const handleEdit = (row, type) => {
    console.log(row, type);
    if (customersSelected) {
      CustomerMarketDetailClient.fetchCustomerMarketDetail({
        customer: customersSelected?.value,
        orig: row?.orig,
        dest: row?.dest,
      })
        .then((res: any) => {
          console.log(res, "res");
          setCountriesSelectedOrigin({
            label: res?.orig_country,
            value: res?.orig_country,
            disabled: false,
          });
          setCountriesSelectedDestination({
            label: res?.dest_country,
            value: res?.dest_country,
            disabled: false,
          });
          setCitiesSelectedOrigin({
            label: res?.orig,
            value: res?.orig,
            disabled: false,
          });
          setCitiesSelectedDestination({
            label: res?.dest,
            value: res?.dest,
            disabled: false,
          });
          setMarketTypeSelected({
            label: res?.direction,
            value: res?.direction,
            disabled: false,
          });
          setStayDuration(res?.stayDuration ?? "");
          setCurrenciesSelected({
            label: res?.currency,
            value: res?.currency,
            disabled: false,
          });
          setStartOffset(res?.startOffset ?? "");
          setEndOffset(res?.endOffset ?? "");
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className={styles.container}>
      <Card
        variant="secondary"
        title="Filter Configuration"
        titlePosition="center"
        fontSize={32}
      >
        <div className={styles.header_wrapper}>
          <span className={styles.select_menu_label}>Customer</span>
          <SelectMenu
            options={customers}
            onSelect={(value) => setCustomerSelected(value ?? null)}
            value={customersSelected}
            style={{
              width: 200,
              marginRight: 8,
            }}
          />
        </div>
        <div className={styles.table_wrapper}>
          <ListOfMarkets
            customer={customersSelected?.value ?? null}
            editMarket={handleEdit}
            data={data}
            labels={labels}
            getTableData={getTableData}
          />
        </div>
        <Card variant="primary">
          <div className={styles.filter_wrapper}>
            <div className={styles.orig_dest_wrapper}>
              <div className={styles.origin}>
                <span className={styles.select_menu_label}>Origin</span>
                <SelectMenu
                  options={countries}
                  onSelect={(value) =>
                    setCountriesSelectedOrigin(value ?? null)
                  }
                  value={countriesSelectedOrigin}
                  style={{ width: 200, marginRight: 8, zIndex: 1000 }}
                />
                <SelectMenu
                  options={citiesOrigin}
                  onSelect={(value) => setCitiesSelectedOrigin(value ?? null)}
                  value={citiesSelectedOrigin}
                  style={{ width: 200, marginRight: 8, zIndex: 1000 }}
                />
              </div>
              <div className={styles.destination}>
                <span className={styles.select_menu_label}>Destination</span>
                <SelectMenu
                  options={countries}
                  onSelect={(value) =>
                    setCountriesSelectedDestination(value ?? null)
                  }
                  value={countriesSelectedDestination}
                  style={{ width: 200, marginRight: 8 }}
                />
                <SelectMenu
                  options={citiesDestination}
                  onSelect={(value) =>
                    setCitiesSelectedDestination(value ?? null)
                  }
                  value={citiesSelectedDestination}
                  style={{ width: 200, marginRight: 8 }}
                />
              </div>
            </div>
            <div className={styles.market_type_stay_duration}>
              <div className={styles.market_type}>
                <span className={styles.select_menu_label}>Market Type</span>
                <SelectMenu
                  options={marketTypes}
                  onSelect={(value) => setMarketTypeSelected(value ?? null)}
                  value={marketTypeSelected}
                  style={{ width: 200, marginRight: 8 }}
                />
              </div>
              <div className={styles.stay_duration}>
                <span className={styles.select_menu_label}>
                  Stay Duration (days)
                </span>
                <Input
                  onChange={(e) => setStayDuration(e.target.value)}
                  value={stayDuration}
                />
              </div>
            </div>
            <div className={styles.currency}>
              <span className={styles.select_menu_label}>Currency</span>
              <SelectMenu
                options={currencies}
                onSelect={(value) => setCurrenciesSelected(value ?? null)}
                value={currenciesSelected}
                style={{ width: 200, marginRight: 8, zIndex: 998 }}
              />
            </div>
            <div className={styles.offset_wrapper}>
              <div className={styles.offset}>
                <span className={styles.select_menu_label}>Start Ofset</span>
                <Input
                  onChange={(e) => setStartOffset(e.target.value)}
                  value={startOffset}
                />
              </div>
              <div className={styles.offset}>
                <span className={styles.select_menu_label}>End Offset</span>
                <Input
                  onChange={(e) => setEndOffset(e.target.value)}
                  value={endtOffset}
                />
              </div>
            </div>
          </div>
          <div className={styles.table_wrapper}>
            <ListOfMarkets
              customer={customersSelected?.value ?? null}
              editMarket={() => {}}
              data={[]}
              labels={[]}
              getTableData={() => {}}
            />
          </div>
          <div className={styles.website_wrapper}>
            <div className={styles.select_menu_wrapper}>
              <span className={styles.select_menu_label}>Website</span>
              <SelectMenu
                options={websites}
                onSelect={(value) => setWebsiteSelected(value ?? null)}
                value={websiteSelected}
                style={{ width: 170, marginRight: 8 }}
              />
            </div>
            <div className={styles.select_menu_wrapper}>
              <span className={styles.select_menu_label}>Scraped Currency</span>
              <SelectMenu
                options={scrapedCurrency}
                onSelect={(value) => setScrapedCurrencySelected(value ?? null)}
                value={scrapedCurrencySelected}
                style={{ width: 170, marginRight: 8 }}
              />
            </div>
            <div className={styles.select_menu_wrapper}>
              <span className={styles.select_menu_label}>
                Carrier to Scrape
              </span>
              <SelectMenu
                options={carrierToScrape}
                onSelect={(value) => setCarrierToScrapeSelected(value ?? null)}
                value={carrierToScrapeSelected}
                style={{ width: 170, marginRight: 8 }}
              />
            </div>
            <div className={styles.website_save_button}>Save</div>
          </div>
          <div className={styles.buttons_wrapper}>
            <span className={styles.cancel_button}>Cancel</span>
            <span className={styles.save_button}>Save</span>
          </div>
        </Card>
      </Card>
    </div>
  );
}
