import { RestClient } from "../RestClient";
import { Fare } from "./model/IFare";
import {
  BusinessRule,
  FareV2,
  MarketFilterData,
  MinFare,
  RuleResult,
  RuleAirports
} from "./types";

export class LFAApiClient {
  /* ===== filters ===== */
  public static async getLFAFilterMarkets(): Promise<MarketFilterData> {
    return await LFAApiClient.getRestClient().getCall(
        `/api/lfa/filters/markets`
    );
  }

  public static async getONDs(type:'airports' | "cities") : Promise<RuleAirports> {
    return await LFAApiClient.getRestClient().getCall(
      `/api/lfa/rules/onds?type=${type}`
    );
  }

  /* ===== business rules ===== */
  public static async getBusinessRules(): Promise<BusinessRule[]> {
    return await LFAApiClient.getRestClient().getCall(`/api/lfa/rules/simple`);
  }

  public static async getBusinessRule(id: string): Promise<BusinessRule> {
    return await LFAApiClient.getRestClient().getCall(`/api/lfa/rules/${id}`);
  }

  public static async deleteBusinessRule(id: string): Promise<BusinessRule[]> {
    return await LFAApiClient.getRestClient().deleteCall(
      `/api/lfa/rules/${id}`
    );
  }

  public static async updateBusinessRule(
    id: string,
    record: any
  ): Promise<BusinessRule> {
    return await LFAApiClient.getRestClient().postCall(
      `/api/lfa/rules/${id}`,
      record
    );
  }

  public static async createBusinessRule(record: any): Promise<any> {
    return await LFAApiClient.getRestClient().putCall(
      `/api/lfa/rules/`,
      record
    );
  }

  /* ===== business rules results ===== */
  public static async getRulesResults(
    origins: string,
    dests: string,
    competitors: string,
    cabinCode?: string
  ): Promise<RuleResult[]> {
    return await LFAApiClient.getRestClient().getCall(
      `/api/lfa/rules-results/simple?originCode=${origins}&destCode=${dests}&opAlCode=${competitors}&cabin=${cabinCode}`
    );
  }
  public static async deleteAllRulesResults(): Promise<RuleResult[]> {
    return await LFAApiClient.getRestClient().deleteCall(
        `/api/lfa/rules-results/`
    );
  }
  /* ===== scraper fares ===== */
  /*    public static async getHostFares():Promise<Fare[]>{
        return await LFAApiClient.getRestClient().getCall('/api/lfa/fares/host/');
    }*/
  // TODO add parameters
  public static async getHostFares(): Promise<Fare[]> {
    return await LFAApiClient.getRestClient().getCall(`/api/lfa/fares/host/`);
  }
  public static async getHostFares2(
    origins: string,
    dests: string
  ): Promise<FareV2[]> {
    return await LFAApiClient.getRestClient().getCall(
      `/api/lfa/fares2/host?originCode=${origins}&destCode=${dests}`
    );
  }

  public static async getCompetitionFares2(
    origins: string,
    dests: string,
    competitors: string
  ): Promise<FareV2[]> {
    return await LFAApiClient.getRestClient().getCall(
      `/api/lfa/fares2/competition/?originCode=${origins}&destCode=${dests}&opAlCode=${competitors}`
    );
  }

  public static async getMinimumFares(
    startDate: number,
    endDate: number,
    source: string,
    destination: string,
    competitors: string,
    mainCompetitor : string,
    type: string
  ): Promise<MinFare[]> {
    let query: string = `?startDate=${startDate}&endDate=${endDate}&source=${source}&destination=${destination}&type=${type}&competitors=${competitors}`;
    if (mainCompetitor) {
      query += `&main_competitor=${mainCompetitor}`
    }

    return await LFAApiClient.getRestClient().getCall(
      `/api/lfa/fares2/availability-calendar${query}`
    );
  }

  public static async getRulesOptionns(key: string): Promise<any> {
    return await LFAApiClient.getRestClient().getCall(
      `/api/lfa/rules/options?key=${key}`
    );
  }

  public static async getFieldValues(
    field: string,
    model: string
  ): Promise<any> {
    return await LFAApiClient.getRestClient().getCall(
      `/api/lfa/rules/values?field=${field}&model=${model}`
    );
  }

  /* ===== business rules engine ===== */

  public static async requestBusinessRulesOptimisation(): Promise<any> {
    try {
      return await LFAApiClient.getRestClient().postCall(
          `/api/lfa/rules-engine/optimize/network`
      );
    } catch (err) {
      console.log("Optimization error:", err);
    }
  }

  private static getRestClient(): RestClient {
    return new RestClient();
  }
}
