/* eslint-disable react-hooks/exhaustive-deps */
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { RuleOverlappingClient } from "lib/api/lfa/rules/RuleOverlapping";
import { PriorityReorderClient } from "lib/api/lfa/rules/PriorityReorder";
import Notification from "components/common/Notification/Notification";
import moment from "moment";
import { InfoOutlined } from "@mui/icons-material";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "components/common/Tooltip";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (_isDragging, draggableStyle, isBase, theme) => ({
  userSelect: "none",
  margin: `0 0 4px 0`,
  padding: 8,
  borderRadius: 8,
  height: 40,
  display: "flex",
  alignItems: "center",

  // change background colour if dragging
  backgroundColor: isBase
    ? "rgba(85, 4, 217, 0.4)"
    : theme === "dark"
    ? "rgba(255, 255, 255, 0.05)"
    : "rgba(0,0,0,0.1)",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  backgroundColor: "transparent",
  width: "100%",
});
interface IDragItems {
  cabinCode: string;
  createdAt: string;
  deptDateEnd: string;
  deptDateStart: string;
  destCityCode: string;
  effectiveDateEnd: string;
  effectiveDateStart: string;
  flightNumber: string;
  id: string;
  isActive: boolean;
  originCityCode: string;
  ruleName: string;
  updatedAt: string;
  username: string;
  isBase: boolean;
  readableId: string;
}
export default function RulesPriority() {
  const [dragItems, setDragItems] = useState<IDragItems[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  function handleBeforeUnload(event): any {
    if (hasChanges) {
      event.preventDefault();
      event.returnValue = "";
      return "Are you sure you want to turn it off?";
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    RuleOverlappingClient.fetchRuleOverlapping(id)
      .then((res: any) => {
        setDragItems(res);
      })
      .catch(() => console.log("Error"));
  }, []);
  const handleHasChanges = async () => {
    setHasChanges(false);
  };
  const handleSave = () => {
    const ids = dragItems?.map((el) => el.id);
    PriorityReorderClient.fetchPriorityReorder({ ids })
      .then(() => {
        Notification({
          type: "success",
          message: "Was recorded. This page will be closed shortly.",
        });
        handleHasChanges().then(() => {
          setTimeout(() => {
            window.close();
          }, 3000);
        });
      })
      .catch((err) =>
        Notification({
          type: "error",
          message: `${err?.originalError?.response?.data?.message ?? "Error"}`,
        })
      );
  };
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items: any = reorder(
      dragItems,
      result.source.index,
      result.destination.index
    );
    setDragItems(items);
    setHasChanges(true);
  };

  const cardInfoJsx = (element) => (
    <div>
      <h4>Other</h4>
      <div>
        <span className={styles.card_info_title}>Region</span>
        <span className={styles.card_info_des}>
          {element?.hoverText?.region ?? "-"}
        </span>
      </div>
      <div>
        <span className={styles.card_info_title}>Competitor</span>
        <span className={styles.card_info_des}>
          {element?.hoverText?.competitor ?? "-"}
        </span>
      </div>
      <div>
        <span className={styles.card_info_title}>Flight Number</span>
        <span className={styles.card_info_des}>
          {element?.flightNumber?.lenght > 0
            ? element?.flightNumber
            : "-" ?? "-"}
        </span>
      </div>
      <div>
        <span className={styles.card_info_title}>Days to Departure</span>
        <span className={styles.card_info_des}>{`${
          (element?.hoverText?.dtd[0] && element?.hoverText?.dtd[0]) ?? "-"
        } - ${
          (element?.hoverText?.dtd[1] && element?.hoverText?.dtd[1]) ?? "-"
        }`}</span>
      </div>
      <div>
        <span className={styles.card_info_title}>Days of Week</span>
        <span className={styles.card_info_des}>
          {element?.hoverText?.dow.lenght > 0
            ? element?.hoverText?.dow?.join()
            : "-" ?? "-"}
        </span>
      </div>
      <div>
        <span className={styles.card_info_title}>Comp Dep Time Range</span>
        <span className={styles.card_info_des}>
          {`${
            (element?.hoverText?.competitor_range[0] &&
              element?.hoverText?.competitor_range[0]) ??
            "-"
          } - ${
            (element?.hoverText?.competitor_range[1] &&
              element?.hoverText?.competitor_range[1]) ??
            "-"
          }`}
        </span>
      </div>
      <div>
        <span className={styles.card_info_title}>Departure Time Range</span>
        <span className={styles.card_info_des}>
          {`${
            (element?.hoverText?.effective_time[0] &&
              element?.hoverText?.effective_time[0]) ??
            "-"
          } - ${
            (element?.hoverText?.effective_time[1] &&
              element?.hoverText?.effective_time[1]) ??
            "-"
          }`}
        </span>
      </div>
      <div>
        <span className={styles.card_info_title}>Equipment</span>
        <span className={styles.card_info_des}>
          {`${
            (element?.hoverText?.equipment[0] &&
              element?.hoverText?.equipment[0]) ??
            "-"
          }`}
        </span>
      </div>
      {element?.hoverText?.analysis?.map((el, index) => (
        <div className={styles.strategy_card}>
          <p className={styles.card_info_title}>
            {index + 1}. Strategy If Conditions
          </p>
          <p className={styles.card_info_des}>{`Apply : ${el?.apply}`}</p>
          <p className={styles.card_info_des}>{`Operator : ${el?.operator}`}</p>
          <p
            className={styles.card_info_des}
          >{`Value : ${el?.value?.join()}`}</p>
        </div>
      ))}
      {[element?.hoverText?.event]?.map((el, index) => (
        <div className={styles.strategy_card}>
          <p className={styles.card_info_title}>
            {index + 1}. Strategy Then Actions
          </p>
          <p className={styles.card_info_des}>{`Category : ${el?.type}`}</p>
          <p className={styles.card_info_des}>{`Action : ${el?.class_rank}`}</p>
          <p className={styles.card_info_des}>{`Set Avail : ${el?.value}`}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.save_button_wrapper}>
          <span className={styles.save_button} onClick={() => handleSave()}>
            Save
          </span>
        </div>
        <div className={styles.card_header}>
          <span className={styles.card_title}>Rules Priority</span>
          <span className={styles.card_description} data-theme={theme}>
            Drag and drop to change order of rules.
          </span>
        </div>

        <div className={styles.card_body}>
          <div className={styles.table_head} data-theme={theme}>
            <span className={styles.th_priority}>PRIORITY</span>
            <span className={styles.th_id}>ID</span>
            <span className={styles.th_rule_name}>RULE NAME</span>
            <span className={styles.th_cabin}>CABIN</span>
            <span className={styles.th_city_pair}>CITY PAIR</span>
            <span className={styles.th_eff_dates}>EFF DATES</span>
            <span className={styles.th_travel_dates}>DEPARTURE DATES</span>
            <span className={styles.th_auto}>AUTO</span>
            <span className={styles.th_owner}>OWNER</span>
            <span className={styles.th_active}>ACTIVE</span>
          </div>
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle()}
                  >
                    {dragItems?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              item.isBase,
                              theme
                            )}
                          >
                            <div className={styles.table_body}>
                              <span className={styles.td_priority}>
                                {Number(index) + 1}
                              </span>
                              <Tooltip title={item?.readableId}>
                                <span className={styles.td_id}>
                                  {item?.readableId}
                                </span>
                              </Tooltip>
                              <span className={styles.td_rule_name}>
                                {item?.ruleName}
                              </span>
                              <span className={styles.td_cabin}>
                                {item?.cabinCode}
                              </span>
                              <span className={styles.td_city_pair}>
                                {`${item?.originCityCode} - ${item?.destCityCode}`}
                              </span>
                              <span className={styles.td_eff_dates}>
                                {`${
                                  moment(
                                    item?.effectiveDateStart,
                                    "YYYY-MM-DD"
                                  ).isValid()
                                    ? moment(
                                        item?.effectiveDateStart,
                                        "YYYY-MM-DD"
                                      ).format("DD MMM YYYY")
                                    : ""
                                } - ${
                                  moment(
                                    item?.effectiveDateEnd,
                                    "YYYY-MM-DD"
                                  ).isValid()
                                    ? moment(
                                        item?.effectiveDateEnd,
                                        "YYYY-MM-DD"
                                      ).format("DD MMM YYYY")
                                    : ""
                                }`}
                              </span>
                              <span className={styles.td_travel_dates}>
                                {`${
                                  moment(
                                    item?.deptDateStart,
                                    "YYYY-MM-DD"
                                  ).isValid()
                                    ? moment(
                                        item?.deptDateStart,
                                        "YYYY-MM-DD"
                                      ).format("DD MMM YYYY")
                                    : ""
                                } - ${
                                  moment(
                                    item?.deptDateEnd,
                                    "YYYY-MM-DD"
                                  ).isValid()
                                    ? moment(
                                        item?.deptDateEnd,
                                        "YYYY-MM-DD"
                                      ).format("DD MMM YYYY")
                                    : ""
                                }`}
                              </span>
                              <span className={styles.td_auto}>
                                {item?.isActive ? "Yes" : "No"}
                              </span>
                              <span className={styles.td_owner}>
                                {item?.username}
                              </span>
                              <span className={styles.td_active}>
                                {item?.isActive ? "Active" : "Deactive"}
                              </span>
                              <span
                                className={styles.td_active_dot}
                                data-active={item?.isActive}
                              />
                              <span className={styles.td_rule_info}>
                                <Tooltip title={cardInfoJsx(item)}>
                                  <InfoOutlined />
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </div>
  );
}
