import { Dispatch, SetStateAction, useState } from "react";
import MainFilterClosed from "./MainFilterClosed";
import MainFilterOpened from "./MainFilterOpened";
import styles from "./MainFilter.module.css";

interface IMainFilter {
  filterMenu: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
}
export default function MainFilter({ filterMenu }: IMainFilter) {
  const [currenciesDropdown, setCurrenciesDropdown] = useState<string[]>([]);
  const [orig, setOrig] = useState(null);
  const [dest, setDest] = useState(null);

  return (
    <div className={styles.container}>
      <div className={styles.filter} data-active={filterMenu.isOpen}>
        <MainFilterOpened
          isOpen={filterMenu.isOpen}
          setIsOpen={filterMenu.setIsOpen}
          currenciesDropdown={currenciesDropdown}
          setCurrenciesDropdown={setCurrenciesDropdown}
          setOrig={setOrig}
          setDest={setDest}
        />
      </div>
      <div className={styles.filter} data-active={!filterMenu.isOpen}>
        <MainFilterClosed
          isOpen={filterMenu.isOpen}
          setIsOpen={filterMenu.setIsOpen}
          currenciesDropdown={currenciesDropdown}
          orig={orig}
          dest={dest}
        />
      </div>
    </div>
  );
}
